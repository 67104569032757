<i18n locale="th" lang="yaml" >
title.error : "พบข้อผิดพลาด"
title.change : "มีการเปลี่ยนแปลง"
notice.change : "ค่าเดิม {value}"
</i18n>

<template>
	<span>
		<MyPopover v-if="isError" :title="$t('title.error')">
			<template slot="content">
				<template v-for="(attName,index) in nameList">
					<div v-if="model.errors[attName] != null" :key="index">
						<b>{{model.errors[attName].name}}</b> :
						<span>{{model.errors[attName].error}}</span>
					</div>
				</template>
			</template>
			<a-icon type="exclamation-circle" class="text-error icon"/>
		</MyPopover>

		<MyPopover v-if="isChange" :title="$t('title.change')">
			<template slot="content">
				<template v-for="(attName,index) in nameList">
					<div v-if="model.changes[attName] != null" :key="index">
						<b>{{model.changes[attName].name}}</b> :
						<span>{{$t('notice.change',{ value: model.changes[attName].old_value })}}</span>
					</div>
				</template>
			</template>
			<a-icon type="info-circle"  class="text-info icon" />
		</MyPopover>
	</span>

</template>

<script>
import MyPopover from "@components/common/MyPopover.vue"
export default {
	components : {
		MyPopover,
	} ,
	props : {
		model : {
			type : null,
			default : () => []
		} ,
		name : {
			type : [String,Array],
			default : ''
		} ,

	} ,
	computed : {
		nameList() {
			if (Array.isArray(this.name)) {
				return this.name;
			} else {
				return [this.name];
			}
		} ,
		isError() {
			if (!this.model.errors)
				return false;
			for(const name of this.nameList) {
				if (this.model.errors[name] !== null && this.model.errors[name] !== undefined)
					return true
			}
			return false
		} ,

		isChange() {
			if (!this.model.changes)
				return false;
			if (this.model.mode === 'update') {
				for(const name of this.nameList) {
					if (this.model.changes[name] !== null && this.model.changes[name] !== undefined)
						return true
				}
			}
			return false
		}
	}
}
</script>

<style lang="less" scoped>
.icon {
	font-size : 16px;
}
</style>
