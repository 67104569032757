import { render, staticRenderFns } from "./DroneImportRegistered.vue?vue&type=template&id=78cd0a10"
import script from "./DroneImportRegistered.vue?vue&type=script&lang=js"
export * from "./DroneImportRegistered.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@i18n/drone/drone.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fdrone%2FDroneImportRegistered.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@i18n/customer/customer.th.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fdrone%2FDroneImportRegistered.vue&locale=th&lang=yaml&external"
if (typeof block1 === 'function') block1(component)
import block2 from "./DroneImportRegistered.vue?vue&type=custom&index=2&blockType=i18n&locale=th&lang=yaml"
if (typeof block2 === 'function') block2(component)

export default component.exports